import { clsx } from '@ui/utils/styles';
import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

type MessageType = 'info' | 'success' | 'warning' | 'error';
export type ToastObject = { text: string; type: MessageType };

const ToastContext = createContext<{
	message: ToastObject | null;
	show: (message: ToastObject) => void;
}>({
	message: null,
	show: () => {
		// unimplemented
	},
});

export const useToast = () => {
	return useContext(ToastContext);
};

const DELAY = 10 * 1000; // 10 seconds

const AlertClass: Record<MessageType, string> = {
	info: 'alert-info',
	success: 'alert-success',
	warning: 'alert-warning',
	error: 'alert-error',
};

const ToastProvider: React.FC<{
	children: ReactNode;
	toast?: ToastObject | null;
}> = ({ children, toast }) => {
	const [message, setMessage] = useState<ToastObject | null>(null);

	const show = useCallback((m: ToastObject) => {
		setMessage(m);
		const t = setTimeout(() => setMessage(null), DELAY);
		return () => {
			clearInterval(t);
			setMessage(null);
		};
	}, []);

	useEffect(() => {
		if (toast) {
			return show(toast);
		}
	}, [show, toast]);

	return (
		<ToastContext.Provider value={{ message, show }}>
			{children}
			{message && (
				<div className="toast">
					<div className={clsx('alert', AlertClass[message.type])}>
						<div>
							<span>{message.text}</span>
						</div>
					</div>
				</div>
			)}
		</ToastContext.Provider>
	);
};
export default ToastProvider;
